<template>
  <div>
    <h2 id="hintergrund">Hintergrundwissen</h2>
    <p>
      Neben dem Zahlverständnis sollen die Lernenden in der Schuleingangsphase
      <strong>ein tragfähiges Verständnis von Rechenoperationen</strong>
      aufbauen. Die Notwendigkeit dieser Zielsetzung ist aus mindestens zwei
      Gründen gegeben. Erstens hat sich gezeigt, dass ein nicht-tragfähiges
      Operationsverständnis häufig bei denjenigen Lernenden zu beobachten ist,
      die Schwierigkeiten in Mathematik haben (Schipper, 2005, S. 53). Anders
      herum: Mit Hilfe eines tragfähigen Operationsverständnisses können Kinder
      leichter lernen, Rechenaufgaben richtig zu bearbeiten und sich deren
      Resultate oder Wege zur Ergebnisermittlung dauerhafter zu merken
      (Gaidoschik, 2007, S. 69 f.). Und zweitens wird ein tragfähiges
      Operationsverständnis immer wieder im weiterführenden Mathematikunterricht
      benötigt: Ein Kind, das über keine Vorstellungen von 3 · 5 verfügt, wird
      mit hoher Wahrscheinlichkeit auch keine Vorstellungen von 1,5 · 2,5 oder
      von <sup>3</sup>&frasl;<sub>2</sub> · <sup>5</sup>&frasl;<sub>2</sub> oder
      allgemein von a · b entwickeln können (Selter & Zannetin, 2018, S. 55).
    </p>

    <p>
      Dem Operationsverständnis liegen drei wesentliche Aspekte zugrunde. Diese
      gelten weithin als Grundlage zur Ausbildung eines umfassenden
      Operationsverständnisses. Daher wird im Folgenden auf diese drei Bereiche
      eingegangen:
      <ol>
        <li><i>Grundvorstellungen besitzen</i></li>
        <li><i>Darstellungen vernetzen</i></li>
        <li><i>Aufgabenbeziehungen nutzen</i></li>
      </ol>
    </p>

    <h3>Grundvorstellungen besitzen</h3>
    <h4>Was solltest Du zu Grundvorstellungen wissen?</h4>
    <p>
      Zur Entwicklung eines tragfähigen Operationsverständnisses müssen
      <strong>Grundvorstellungen zu Rechenoperationen</strong> aufgebaut werden.
      Unter Grundvorstellungen kann man hier die für das Mathematiklernen
      wichtigen Bedeutungszuschreibungen verstehen, die dazu beitragen, dass die
      Kinder sich unter einer Rechenoperation konkrete Aktivitäten vorstellen
      können und das Rechnen nicht auf das Manipulieren mit Symbolen reduzieren.
    </p>
    <p>
      Natürlich sollen die Schülerinnen und Schüler nicht lernen, die
      unterschiedlichen Grundvorstellungen explizit zu benennen. Aber Du als
      Lehrpersonen solltest diese Unterschiede kennen, um Aufgaben einzusetzen,
      die die unterschiedlichen Grundvorstellungen repräsentieren und auch, um
      Schwierigkeiten zu erkennen, die Lernende haben können.
    </p>
    <p>
      In der Addition und der Subtraktion lassen sich jeweils drei
      Grundvorstellungen unterscheiden, wie die folgende Tabelle zeigt:
    </p>

    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr style="text-align: center">
            <td colspan="2">Addition</td>
          </tr>
          <tr>
            <td><i>Hinzufügen</i></td>
            <td>
              Zu einer Anzahl von Objekten werden weitere Objekte
              hinzugefügt.<br />
              Beispiel: Sandra hat 3 Gummibärchen. Sie nimmt sich noch 5 Weitere
              aus der Tüte, wie viele hat sie jetzt?
            </td>
          </tr>
          <tr>
            <td><i>Zusammenfassen</i></td>
            <td>
              Zwei Mengen werden zusammengelegt.<br />
              Beispiel: Sandra hat 5 Gummibärchen. Güney hat ebenfalls 5
              Gummibärchen. Wie viele Gummibärchen haben sie zusammen?
            </td>
          </tr>
          <tr>
            <td><i>Vergleichen</i></td>
            <td>
              Zwei Mengen werden verglichen, indem ihre Differenz additiv
              ermittelt wird.<br />
              Beispiel: Sandra hat 8 Gummibärchen, aber Güney hat 2 mehr. Wie
              viele Gummibärchen hat Güney?
            </td>
          </tr>

          <tr>
            <td colspan="2" style="text-align: center">Subtraktion</td>
          </tr>

          <tr>
            <td><i>Abziehen</i></td>
            <td>
              Eine Anzahl von Objekten wird einer Menge weggenommen. Es bleibt
              ein Rest bestehen.<br />
              Beispiel: Julia hat 5 Kekse, sie isst 2. Wie viele Kekse hat sie
              noch über?
            </td>
          </tr>
          <tr>
            <td><i>Ergänzen</i></td>
            <td>
              Ein Unterschied wird dynamisch bestimmt.<br />
              Beispiel: Elias möchte einen Ball kaufen. Der Ball kostet 15€, er
              hat aber nur 10€ dabei. Wie viel Euro muss er aus der Spardose
              nehmen, damit er den Ball bezahlen kann?
            </td>
          </tr>
          <tr>
            <td><i>Vergleichen</i></td>
            <td>
              Ein Unterschied wird statisch bestimmt.<br />
              Beispiel: Ute ist 125cm groß. Benjamin ist 133cm groß. Um wie
              viele Zentimeter ist Benjamin größer?
            </td>
          </tr>

          <tr>
            <td colspan="2" style="text-align: center">Division</td>
          </tr>

          <tr>
            <td><i>Aufteilen</i></td>
            <td>
              Die Gruppengröße ist bekannt. Die Gruppenanzahl muss bestimmt
              werden.<br />
              Beispiel: In der Klasse sind 24 Kinder. In jede Gruppe sollen 4
              Kinder. Wie viele Gruppen müssen gebildet werden?
            </td>
          </tr>
          <tr>
            <td><i>Verteilen</i></td>
            <td>
              Die Gruppenanzahl ist bekannt. Die Gruppengröße muss bestimmt
              werden.<br />
              Beispiel: In der Klasse sind 24 Kinder. Es sollen 6 Gruppen
              gebildet werden. Wie viele Kinder sind in einer Gruppe?
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <br />

    <p>
      Verfügt ein Kind beispielsweise bei der Addition nur über die
      Grundvorstellung des dynamischen Hinzufügens, so könnte es durchaus
      Schwierigkeiten bei Aufgaben des eher statischen Vergleichens bekommen.
      Dadurch, dass die Lernenden Sachaufgaben bzw. Rechengeschichten mit
      vielfältigen Sachkontexten kennenlernen und – anders herum –
      Rechenaufgaben Sachsituationen zuordnen, kann dem entgegengewirkt werden
      (Häsel-Weide & Nührenbörger, 2012, S. 29).
    </p>
    <p>
      Nachdem Du nun einen Überblick über die Grundvorstellungen der Addition,
      Subtraktion und Division erhalten hast, werden im Folgenden die drei
      Grundvorstellungen der Multiplikation dargestellt und anhand von
      Beispielen gezeigt, welche Rechengeschichten zu welcher bzw. zu keiner
      Grundvorstellung passen.
    </p>

    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr style="text-align: center">
            <td colspan="2">Multiplikation</td>
          </tr>

          <tr>
            <td><i>Wiederholen/zeitlich-sukzessiv</i></td>
            <td>
              Eine Anzahl von Objekten wird zeitlich wiederholt
              zusammengefasst.<br />
              Beispiele:
              <ol>
                <li>
                  Fiona macht jeden Tag 30 Minuten Hausaufgaben. Wie lange macht
                  sie in 5 Tagen Hausaufgaben?
                </li>
                <li>
                  Tim geht dreimal in den Keller und holt immer vier Flaschen
                  Apfelsaft. Wie viele Flaschen hat er geholt?
                </li>
                <li>
                  Jana geht jede Woche 12 km joggen. Wie viele Kilometer ist sie
                  nach 6 Wochen gejoggt?
                </li>
                <li>
                  Steven isst jeden Tag zwei Scheiben Brot. Wie viele Scheiben
                  hat er in einer Woche gegessen?
                </li>
              </ol>
            </td>
          </tr>
          <tr>
            <td><i>Zusammenfassen/Räumlich-simultan</i></td>
            <td>
              Mehrere Mengen mit gleicher Anzahl von Objekten werden wiederholt
              zusammengefasst.<br />
              Beispiele:
              <ol>
                <li>
                  Eine Tafel Schokolade hat 3 Stück Schokolade pro Riegel. 5
                  solcher Riegel bilden eine Tafel. Wie viele Stücke Schokolade
                  hat eine Tafel?
                </li>
                <li>
                  An einem Gruppentisch in der Klasse sitzen 5 Kinder. Es gibt 6
                  Gruppentische. Wie viele Kinder sind in der Klasse?
                </li>
                <li>
                  In eine Schüssel passen 6 Brötchen. Es stehen 3 Schüsseln auf
                  dem Tisch. Wie viele Brötchen stehen insgesamt auf dem Tisch?
                </li>
                <li>
                  Lilly kauft 3 Beutel Zitronen. In einem Beutel sind jeweils 5
                  Zitronen. Wie viele Zitronen hat Lilly nun?
                </li>
              </ol>
            </td>
          </tr>
          <tr>
            <td><i>Vergleichen</i></td>
            <td>
              Es werden multiplikative Vergleiche zwischen Größen und Anzahlen
              angestellt.<br />
              Beispiele:
              <ol>
                <li>
                  Kira arbeitet 20 Stunden in der Woche, Andrea arbeitet doppelt
                  so viel. Wie viel arbeitet Andrea?
                </li>
                <li>
                  Tim geht zweimal die Woche joggen. Lars geht doppelt so oft
                  joggen. Wie oft joggt Lars?
                </li>
                <li>
                  Du hast drei Bonbons. Deine Schwester hat dreimal so viele
                  Bonbons wie Du. Wie viele Bonbons hat deine Schwester?
                </li>
                <li>
                  Meike bekommt 8 Euro Taschengeld im Monat. Sven bekommt
                  viermal so viel. Wie viel Taschengeld bekommt Sven?
                </li>
              </ol>
            </td>
          </tr>
          <tr>
            <td><i>Keinerlei Grundvorstellung</i></td>
            <td>
              Ebenso können Rechengeschichten keinerlei multiplikative Struktur
              aufweisen. Hier passen zwar die Zahlen teilweise zu
              Multiplikationsaufgaben und deren Ergebnis, aber es ist keinerlei
              Grundvorstellung sichtbar.<br />
              Beispiele:
              <ol>
                <li>
                  Für 12 Kilometer Fahrradweg brauchen zwei Sportler in etwa 20
                  Minuten.
                </li>
                <li>
                  Fünf Erwachsene und zwei Kinder essen zusammen insgesamt 10
                  Brötchen.
                </li>
                <li>
                  Drei Omas gehen mit ihren insgesamt vier Hunden zusammen 12
                  Minuten spazieren.
                </li>
                <li>
                  Im 2 Wochen Urlaub hat Samuel seiner Freundin insgesamt 5
                  Postkarten aus 3 verschiedenen Ländern geschickt.
                </li>
              </ol>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <br />

    <p>
      Dieses Wissen um Grundvorstellungen im Allgemeinen ermöglicht es nun der
      Lehrkraft, den Kindern Aufgaben zu stellen, die die Kinder mit allen
      möglichen Grundvorstellungen konfrontieren, sodass die Schülerinnen und
      Schüler ein möglichst umfassendes Bild der Operationen aufbauen können.
      Auch wenn die Kinder über das explizite Wissen (wie heißt welche
      Grundvorstellung, wie viele Grundvorstellungen gibt es beispielsweise zur
      Multiplikation, etc.) nicht verfügen müssen, ist es zentral, dass die
      Lehrkraft alle Grundvorstellungen indirekt thematisiert.
    </p>

    <v-card outlined raised>
      <v-card-title
        >Was sollen die Schülerinnen und Schüler lernen?</v-card-title
      >
      <v-card-text>
        Die Kinder können ...
        <ul>
          <li>
            zu (simulierten) Alltagssituationen oder Gegenständen, die den
            verschiedenen Grundvorstellungen entsprechen, passende
            Rechenoperationen nennen, ohne dabei die Grundvorstellung benennen
            zu müssen
          </li>
          <li>Sachsituationen einer Rechenoperation (begründet) zuordnen</li>
          <li>zu einer Sachsituation die passende Rechenaufgabe notieren</li>
        </ul>
      </v-card-text>
    </v-card>
    <br />

    <h3>Darstellungen vernetzen</h3>
    <h4>Was solltest Du zum Darstellungswechsel wissen?</h4>
    <p>
      Neben dem Wissen um die Grundvorstellungen sollten Lehrkräfte aber auch
      Wissen zum Wechsel zwischen den Darstellungsformen haben. Die
      Darstellungsformen lassen sich dabei in vier Kategorien unterscheiden, wie
      die folgende Abbildung zeigt:
    </p>
    <p style="text-align: center">
      <v-img
        contain
        width="400px"
        :src="require('@/assets/ove/ove_darstellungsformen.png')"
        style="margin: 10px auto 0px"
      ></v-img>
      <i style="text-align: center"
        >entnommen aus https://primakom.dzlm.de/node/187</i
      >
    </p>

    <p>
      Das heißt beispielsweise, dass Kinder zu der Aufgabe 3 · 4 eine
      Rechengeschichte erstellen, die im Sinne der gerade erarbeiteten
      Grundvorstellungen sinnvoll ist, oder dass sie ein dazu passendes Bild
      malen können. Die Kinder können dann aber auch beispielsweise aus einer
      geeigneten Handlung eine Rechnung erstellen. Andersherum kann natürlich
      auch jeder Rechenaufgabe eine dazugehörige Alltagssituation oder
      Rechengeschichte zugeschrieben werden, dass die zugrundeliegende Operation
      durch den Kontext repräsentiert wird. Das sollte sowohl zum
      Vorstellungsaufbau genutzt werden, ist aber auch eine eigene zu erlernende
      Kompetenz. Auch nach der Automatisierung des sogenannten kleinen
      Einspluseins oder Einmaleins sollten die Lernenden in der Lage sein, die
      Darstellungen flexibel zu wechseln.
    </p>

    <div style="display: flex">
      <div style="float: left">
        Für die unterschiedlichen Darstellungen gilt: Sie sind einerseits
        Lernhilfe, da sie es den Schülerinnen und Schülern erleichtern
        allgemeine, universellere mathematische Begriffe zu erwerben. Sie sind
        andererseits aber stets auch Lernstoff. Denn es gibt keine simple
        Eins-zu-eins-Zuordnung zwischen Darstellungsmittel und mathematischem
        Begriff, keine Einbahnstraße vom ‚Konkreten‘ zum ‚Abstrakten‘: Der
        abstrakte mathematische Begriff muss durch einen geistigen Akt in das
        Darstellungsmittel hineingelesen werden. Dessen Bedeutung sowie die
        Formen des Umgangs mit ihm müssen von den Schülerinnen und Schülern erst
        erlernt werden.
      </div>
      <v-img
        contain
        width="300px"
        :src="require('@/assets/ove/ove_4plus3.png')"
        style="float: right"
      ></v-img>
    </div>
    <br />
    <p>
      Es hat sich gezeigt, dass Kinder beim Darstellungswechsel auf
      unterschiedliche Kriterien achten (Kuhnke, 2012). Im Folgenden wird dies
      anhand der Beispielaufgabe, passende bildliche Darstellungen zu der
      vorgegebenen Aufgabe 3 · 4 auszuwählen, verdeutlicht.
    </p>

    <p>
      Das scheint auf den ersten Blick noch recht simpel, doch die Komplexität
      nimmt in den folgenden Videos sukzessive zu. Das dient vor allem dazu, um
      sich in die Kinder hineinversetzen zu können. Der Vorstellungsaufbau – der
      mit der Fähigkeit zum Darstellungswechsel einhergeht – für eine neue oder
      ungewohnte, wenig genutzte und geschulte Grundvorstellung erscheint
      dadurch weniger trivial und ist vermutlich zunächst komplexer und etwas
      anspruchsvoller nachzuvollziehen.
    </p>

    <video
      preload="auto"
      controls
      width="60%"
      style="margin-left: 20%"
      @play="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
      @fullscreenchange="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
      @webkitfullscreenchange="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
      @mozfullscreenchange="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
      @msfullscreenchange="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
      @ended="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
      @pause="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
      @seeking="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
      @enterpictureinpicture="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
      @leavepictureinpicture="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
    >
      <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
      <source src="@/assets/ovi/ODWH 1_neu.mp4" type="video/mp4" />
    </video>

    <p>
      In der Multiplikation zweier natürlicher Zahlen fällt es häufig leichter
      eine Einteilung vorzunehmen. Schließlich haben die meisten eine
      Vorstellung zur Multiplikation dazu schon aufgebaut. Den Kindern fällt
      dies häufig noch schwerer, so werden beispielsweise nur Teile der Aufgabe
      – wie Multiplikator und Multiplikand oder das Ergebnis – fokussiert.
    </p>

    <video
      preload="auto"
      controls
      width="60%"
      style="margin-left: 20%"
      @play="videoPlayerEvent($event, 'ODWH 2_neu.mp4')"
      @fullscreenchange="videoPlayerEvent($event, 'ODWH 2_neu.mp4')"
      @webkitfullscreenchange="videoPlayerEvent($event, 'ODWH 2_neu.mp4')"
      @mozfullscreenchange="videoPlayerEvent($event, 'ODWH 2_neu.mp4')"
      @msfullscreenchange="videoPlayerEvent($event, 'ODWH 2_neu.mp4')"
      @ended="videoPlayerEvent($event, 'ODWH 2_neu.mp4')"
      @pause="videoPlayerEvent($event, 'ODWH 2_neu.mp4')"
      @seeking="videoPlayerEvent($event, 'ODWH 2_neu.mp4')"
      @enterpictureinpicture="videoPlayerEvent($event, 'ODWH 2_neu.mp4')"
      @leavepictureinpicture="videoPlayerEvent($event, 'ODWH 2_neu.mp4')"
    >
      <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
      <source src="@/assets/ovi/ODWH 2_neu.mp4" type="video/mp4" />
    </video>

    <p>
      Die Schwierigkeit ist durch einen Bruch sicherlich bereits erhöht und
      erfordert weitere Überlegungen. Noch komplexer wird die Multiplikation
      zweier Brüche.
    </p>

    <video
      preload="auto"
      controls
      width="60%"
      style="margin-left: 20%"
      @play="videoPlayerEvent($event, 'ODWH 3_neu.mp4')"
      @fullscreenchange="videoPlayerEvent($event, 'ODWH 3_neu.mp4')"
      @webkitfullscreenchange="videoPlayerEvent($event, 'ODWH 3_neu.mp4')"
      @mozfullscreenchange="videoPlayerEvent($event, 'ODWH 3_neu.mp4')"
      @msfullscreenchange="videoPlayerEvent($event, 'ODWH 3_neu.mp4')"
      @ended="videoPlayerEvent($event, 'ODWH 3_neu.mp4')"
      @pause="videoPlayerEvent($event, 'ODWH 3_neu.mp4')"
      @seeking="videoPlayerEvent($event, 'ODWH 3_neu.mp4')"
      @enterpictureinpicture="videoPlayerEvent($event, 'ODWH 3_neu.mp4')"
      @leavepictureinpicture="videoPlayerEvent($event, 'ODWH 3_neu.mp4')"
    >
      <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
      <source src="@/assets/ovi/ODWH 3_neu.mp4" type="video/mp4" />
    </video>

    <p>
      Die Vernetzung der Darstellungen ist für ungewohntere Aufgaben
      schwieriger. Für die Kinder, die gerade erst den Wechsel zwischen den
      Darstellungen kennenlernen, ist dies ebenfalls nicht trivial. Um ein
      umfangreiches Operationsverständnis aufzubauen, sind Darstellungswechsel
      und somit eine Vernetzung aller Darstellungsformen aber hilfreich.
    </p>
    <p>
      Erstaunlich und nicht zu vernachlässigen ist die Tatsache, dass viele
      Kinder bereits einzelne Malaufgaben kennen, bevor die Multiplikation im
      Unterricht eingeführt wurde. Daher ist es nicht verwunderlich, dass einige
      Kinder beim Darstellungswechsel von symbolischen und bildlichen
      Darstellungen auf das <i>Gesamtergebnis</i> achten. Dementsprechend
      ‚passen‘ Darstellungen für sie zusammen, wenn beide dasselbe Ergebnis
      haben. Beispielsweise werden alle Darstellungen mit 12 Elementen –
      unabhängig von deren Anordnung – der Aufgabe 3 · 4 zugeordnet. Einige
      Kinder fokussieren besonders auch <i>einzelne Elemente</i>, die in beiden
      Darstellungen vorkommen müssen. Sie nehmen zum Beispiel die 4 in den Blick
      und wählen weitere Darstellungen aus, bei welchen mehrmals 4 zu sehen
      sind. Andere Kinder fokussieren sich auf die Relation der Elemente.
      Darstellungen ‚passen‘ immer dann zusammen, wenn in beiden dieselbe
      Relation zu finden ist. Für das Beispiel 3 · 4 heißt das: Alle
      Darstellungen in welchen genau drei Vierer zu finden sind, auch unabhängig
      von der Anordnung innerhalb der Darstellung, werden als passend
      klassifiziert.
    </p>
    <p>
      Gerade bei schwächeren Kindern zeigt sich, dass Handlungen am konkreten
      Material weniger eine Hürde darstellen. Die Umstellung aber vom Konkreten
      weg, hin zum gedanklichen Handeln, scheint den Kindern schwer zu fallen
      (vgl. vom Hofe 1995). Daher haben Wartha und Schulz ein vier Phasen Modell
      zur Unterstützung entworfen: 1. Handlung am geeigneten Material, 2.
      Beschreibung der Materialhandlung mit Sicht auf das Material, 3.
      Beschreibung der Materialhandlung ohne Sicht auf das Material, 4. Arbeiten
      auf symbolischer Ebene, Üben und Automatisieren (vgl. Wartha &amp; Schulz
      2011). Um folglich zu versuchen möglichst vielen Kindern den
      Darstellungswechsel zu erleichtern, erscheint eine Heranführung nach den
      oben genannten Schritten sinnvoll.
    </p>

    <v-card outlined raised>
      <v-card-title
        >Was sollen die Schülerinnen und Schüler lernen?</v-card-title
      >
      <v-card-text>
        Die Kinder können ...
        <ul>
          <li>
            Darstellungsmitteln die intendierte(n) Aufgabe(n) zuordnen
            (Lernstoff)
          </li>
          <li>
            Darstellungsmittel zur Lösung einer Aufgabe nutzen (Lernhilfe)
          </li>
          <li>
            die Darstellung einer Aufgabe flexibel von einer Darstellung in eine
            andere übertragen
          </li>
          <li>
            die Erklärung einer anderen Person zu einem Darstellungswechsel
            wiedergeben
          </li>
          <li>ausgeführte Darstellungswechsel erklären</li>
          <li>
            beim Darstellungswechsel alle Elemente einer Aufgabe berücksichtigen
            und sich nicht nur auf einen Einzelaspekt beschränken
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <br />
    <br />

    <h3>Aufgabenbeziehungen nutzen</h3>
    <h4>Was solltest Du zum Nutzen von Aufgabenbeziehungen wissen?</h4>
    <p>
      Als dritte Komponente ist die Nutzung von Beziehungen – einerseits
      zwischen Aufgaben, andererseits aber auch zwischen den Rechenoperationen –
      von besonderer Bedeutung für die Entwicklung eines tragfähigen
      Operationsverständnisses. Ohne diese können sich sichere und flexible
      Kompetenzen im Rechnen nicht entwickeln.
    </p>
    <p>
      Zum Rechnen allgemein und insbesondere zum Erlernen des schnellen
      Kopfrechnens ist es wichtig, Beziehungen zwischen Aufgaben herzustellen
      bzw. zu sehen, um die Ergebnisse bereits beherrschter Aufgaben zur
      Berechnung anderer Aufgaben zu nutzen. Es ist natürlich am Ende des
      Lernprozesses eine Automatisierung des kleinen Einmaleins ein Ziel. Das
      sollte aber nicht zu früh angebahnt werden, um dem beziehungsreichen
      Lernen und Abteilen von Aufgaben den nötigen Stellenwert zuschreiben zu
      können. Dabei spielen Rechengesetze der elementaren Arithmetik eine
      besondere Rolle. Die Lernenden können diese verwenden, ohne die Gesetze in
      der allgemeinen Formulierung kennen zu müssen – letzteres soll auch gar
      nicht Ziel des Mathematikunterrichts der Grundschule sein.
    </p>
    <p>
      Um sich genauer damit zu beschäftigen, wie Kinder Aufgaben nutzen und
      welche Rechengesetze und Zusammenhänge dahinterstecken, hat Mia bei
      einigen Aufgaben erklärt, wie sie vorgeht.
    </p>

    <p>
      Hör dir zunächst einmal an wie Mia 11+178 und 14+185 rechnet. Anschließend
      erfolgt die Einordung, welche Gesetzmäßigkeiten Mia nutzt, um so rechnen
      zu dürfen.
    </p>

    <v-row justify="center">
      <v-col cols="6">
        <vuetify-audio
          ref="audioPlayer5"
          :file="require('@/assets/ovi/Drehbuecher/OVI_OABH2_1.mp3')"
          color="primary"
          flat
          :ended="() => audioEnded('Drehbuecher/OVI_OABH2_1.mp3')"
          @click.native="
            () =>
              audioPlayerClicked(
                $event,
                'Drehbuecher/OVI_OABH2_1.mp3',
                $refs.audioPlayer5
              )
          "
          class="audioPlayerBigger"
        ></vuetify-audio>
      </v-col>
    </v-row>

    <p>
      Hör dir nun erst einmal an wie Mia 12⋅5 und 4⋅14 rechnet. Auch hier greift
      Mia auf ein Rechengesetz zurück.
    </p>

    <v-row justify="center">
      <v-col cols="6">
        <vuetify-audio
          ref="audioPlayer5"
          :file="require('@/assets/ovi/Drehbuecher/OVI_OABH2_2.mp3')"
          color="primary"
          flat
          :ended="() => audioEnded('Drehbuecher/OVI_OABH2_2.mp3')"
          @click.native="
            () =>
              audioPlayerClicked(
                $event,
                'Drehbuecher/OVI_OABH2_2.mp3',
                $refs.audioPlayer5
              )
          "
          class="audioPlayerBigger standAloneAudio"
        ></vuetify-audio>
      </v-col>
    </v-row>

    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td>
              <!-- TODO: Audio File 13+9-->
              <v-row>
                <v-col cols="audioTabelleFormat" class="text-center" align-self="center">
                  <vuetify-audio
                    ref="audioPlayer5"
                    :file="require('@/assets/ove/Stempeln/mia_13plus9.mp4')"
                    color="primary"
                    flat
                    :ended="() => audioEnded('Stempeln/mia_13plus9.mp4')"
                    @click.native="
                      () =>
                        audioPlayerClicked(
                          $event,
                          'Stempeln/mia_13plus9.mp4',
                          $refs.audioPlayer5
                        )
                    "
                    class="audioPlayerBigger"
                  ></vuetify-audio>
                  13+9 und 38+7
                </v-col>
                <v-col :cols="12-audioTabelleFormat">
                  Jetzt hast Du gehört wie Mia zwei Additionsaufgaben löst. Im
                  Folgenden erklären wir dir nochmal ausführlicher für das
                  Beispiel 15+8, wie Mia denkt und warum sie so rechnen darf.<br />
                  Die Aufgabe 15+8 hat Mia mit Hilfe des Assoziativgesetz
                  gelöst, also (a+b)+c = a +(b+c). Das heißt Mia hat den zweiten
                  Summanden so zerlegt, dass der Zehnerübergang erleichtert
                  wird, also aus 8 wird 5+3 und hat dann 15 mit 5 addiert und zu
                  der 20 addiert sie anschließend 3 und erhält 23.<br />
                  Durch die Zerlegung eines Summanden kann der Zehnerübergang
                  bei einigen Aufgaben erleichtert werden, dabei ist es
                  irrelevant, welcher Summand zerlegt wird.
                </v-col>
              </v-row>
            </td>
          </tr>
          <tr>
            <td>
              <!-- TODO: Audio File 19+21-->
              <v-row>
                <v-col cols="audioTabelleFormat" class="text-center" align-self="center">
                  <vuetify-audio
                    ref="audioPlayer5"
                    :file="require('@/assets/ove/Stempeln/mia_19plus21.mp4')"
                    color="primary"
                    flat
                    :ended="() => audioEnded('Stempeln/mia_19plus21.mp4')"
                    @click.native="
                      () =>
                        audioPlayerClicked(
                          $event,
                          'Stempeln/mia_19plus21.mp4',
                          $refs.audioPlayer5
                        )
                    "
                    class="audioPlayerBigger"
                  ></vuetify-audio>
                  19+21 und 18+34
                </v-col>
                <v-col :cols="12-audioTabelleFormat">
                  Jetzt hast Du gehört wie Mia zwei Additionsaufgaben löst. Im
                  Folgenden erklären wir dir nochmal ausführlicher für das
                  Beispiel 17+18, wie Mia denkt und warum sie so rechnen
                  darf.<br />
                  Die Aufgabe 17+18 hat Mia auch mit Hilfe eines
                  Konstanzgesetzes – hier das Konstanzgesetz der Summe – gelöst,
                  also a+b = (a±c) + (b∓c). Das heißt Mia hat die 17 um 2
                  vermindert und kommt so auf 15 und die 18 um 2 erhöht und
                  kommt damit auf 20, zum Schluss hat sie dann beide errechneten
                  Zahlen, 15 und 20, addiert und erhält 35.<br />
                  Durch die gegensinnige Veränderung beider Summanden wird eine
                  leichtere Aufgabe erzeugt, da durch einen glatten Zehner der
                  Zehnerübergang umgangen wird.
                </v-col>
              </v-row>
            </td>
          </tr>
          <tr>
            <td>
              <!-- TODO: Audio File 8*7-->
              <v-row>
                <v-col cols="audioTabelleFormat" class="text-center" align-self="center">
                  <vuetify-audio
                    ref="audioPlayer5"
                    :file="require('@/assets/ove/Stempeln/mia_8mal7.mp4')"
                    color="primary"
                    flat
                    :ended="() => audioEnded('Stempeln/mia_8mal7.mp4')"
                    @click.native="
                      () =>
                        audioPlayerClicked(
                          $event,
                          'Stempeln/mia_8mal7.mp4',
                          $refs.audioPlayer5
                        )
                    "
                    class="audioPlayerBigger"
                  ></vuetify-audio>
                  8·7 und 8·3
                </v-col>
                <v-col :cols="12-audioTabelleFormat">
                  Jetzt hast Du gehört wie Mia zwei Multiplikationsaufgaben
                  löst. Im Folgenden erklären wir dir nochmal ausführlicher für
                  das Beispiel 6·9, wie Mia denkt und warum sie so rechnen
                  darf.<br />
                  Die Aufgabe 6·9 hat Mia auch mit Hilfe des Distributivgesetzes
                  also a·b+a·c = a · (b+c) gelöst. Das heißt sie hat die 6 in
                  (5+1) zerlegt und beide Summanden jeweils mit 9 multipliziert.
                  Dann hat sie beide Zwischenergebnisse, 45 und 9 mit einander
                  addiert und kommt so auf das richtige Ergebnis 54.<br />
                  Die Zerlegung kann das Berechnen von einfacheren Aufgaben zur
                  Folge haben und ist somit sinnvoll, um nicht nur das kleine
                  Einmaleins auswendig zu lernen, sondern beziehungsreich und
                  somit anschlussfähig zu lernen. Der Multiplikator oder der
                  Multiplikand kann also zerlegt werden, sodass einfachere
                  Aufgaben entstehen.
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <br />

    <p>
      Zur Übersicht dient die folgende Tabelle, die die Gesetze und
      Zusammenhänge, die Du gerade in der Aktivität schon angewendet hast, noch
      einmal übersichtlich darstellt.
    </p>

    <v-row>
      <v-col cols="gesetzeTabelleFormat">
        <!-- Kommutativgesetze-Tabelle -->
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr style="text-align: center">
                <td colspan="2"><strong>Kommutativgesetz</strong></td>
              </tr>
              <tr>
                <td>
                  <strong>Addition:</strong> Die Summanden einer
                  Additionsaufgabe können vertauscht werden, ohne dass sich
                  dabei das Ergebnis ändert.
                </td>
                <td class="no-wrap">
                  <v-row justify="center">8 + 3 = 3 + 8</v-row>
                  <v-row justify="center">a + b = b + a</v-row>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Multiplikation:</strong> Die Faktoren einer
                  Multiplikationsaufgabe können vertauscht werden, ohne dass
                  sich dabei das Ergebnis ändert.
                </td>
                <td class="no-wrap">
                  <v-row justify="center">7 · 4 = 4 · 7</v-row>
                  <v-row justify="center">a · b = b · a</v-row>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <i
                    >Das <b>Kommutativgesetz</b> gilt nicht für die Subtraktion
                    (8 – 4 ist ungleich 4 – 8) und auch nicht für die Division
                    (6 : 3 ist ungleich 3 : 6)</i
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>

      <v-col cols="gesetzeTabelleFormat">
        <!-- Assoziativgesetz-Tabelle-->
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr style="text-align: center">
                <td colspan="2"><strong>Assoziativgesetz</strong></td>
              </tr>
              <tr>
                <td>
                  <strong>Addition:</strong> Die Summanden einer
                  (mehrgliedrigen) Additionsaufgabe können beliebig miteinander
                  verbunden werden.
                </td>
                <td class="no-wrap">
                  <v-row justify="center">(2 + 3) + 1 = 2 +(3 + 1)</v-row>
                  <v-row justify="center">(a + b) + c = a + (b + c)</v-row>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Multiplikation:</strong> Die Faktoren einer
                  (mehrgliedrigen) Multiplikationsaufgabe können beliebig
                  miteinander verbunden werden.
                </td>
                <td class="no-wrap">
                  <v-row justify="center">(6 · 5) · 7 = 6 · (5 · 7)</v-row>
                  <v-row justify="center">(a · b) · c = a · (b · c)</v-row>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <i
                    >Das <b>Assoziativgesetz</b> gilt nicht für die Subtraktion,
                    denn (8 – 4) – 3 = 4 – 3 = 1 ist ungleich 8 – (4 – 3) = 8 –
                    1 = 7 und auch nicht für die Division: (36 : 6) : 3 = 6 : 3
                    = 2 ist ungleich 36 : (6 : 3) = 36 : 2 = 18.</i
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="gesetzeTabelleFormat">
        <!-- Distributivgesetz-Tabelle-->
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr style="text-align: center">
                <td colspan="2"><strong>Distributivgesetz</strong></td>
              </tr>
              <tr>
                <td cols="8">
                  <strong>Addition/ Multiplikation bzw. Division:</strong>
                  Eine Summe wird mit einem Faktor multipliziert (bzw. durch
                  einen Divisor dividiert), indem jeder Summand mit dem Faktor
                  multipliziert (bzw. durch den Divisor dividiert) wird und die
                  Resultate addiert werden.
                </td>
                <td class="no-wrap">
                  <v-row justify="center">6 · 4 = 2 · 4 + 4 · 4</v-row>
                  <v-row justify="center">(a + b) · c = a · c + b · c</v-row>
                  <br />
                  <v-row justify="center">72 ː 8 = 56 ː 8 + 16 ː 8</v-row>
                  <v-row justify="center">(a + b) ː c = a ː c + b ː c</v-row>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Subtraktion/ Multiplikation bzw. Division:</strong>
                  Eine Differenz wird mit einem Faktor multipli-ziert (bzw.
                  durch einen Divisor dividiert), indem Minuend und Subtrahend
                  mit dem Faktor multipliziert (bzw. durch den Divisor
                  dividiert) werden und die Resultate voneinander sub-trahiert
                  werden.
                </td>
                <td class="no-wrap">
                  <v-row justify="center">7 · 4 = 10 · 4 – 3 · 4</v-row>
                  <v-row justify="center">(a – b) · c = a · c – b · c</v-row>
                  <br />
                  <v-row justify="center">186 ː 6 = 198 ː 6 – 12 ː 6</v-row>
                  <v-row justify="center">(a- b) ː c = a ː c – b ː c</v-row>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <i
                    >Analog gilt das <b>Distributivgesetz</b> bei der
                    Multiplikation auch, wenn der zweite Faktor „zerlegt“ wird.
                    Die Zerlegung des Divisors funktioniert aber in der Regel
                    nicht.</i
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>

      <v-col cols="gesetzeTabelleFormat">
        <!-- Konstanzgesetz-Tabelle-->
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr style="text-align: center">
                <td colspan="2"><strong>Konstanzgesetz</strong></td>
              </tr>
              <tr>
                <td>
                  <strong>Addition:</strong> Das Ergebnis ändert sich nicht,
                  wenn man eine Zahl vergrößert und die andere entsprechend
                  verkleinert.
                </td>
                <td class="no-wrap">
                  <v-row justify="center">3 + 5 = 4 + 4</v-row>
                  <v-row justify="center">a + b = (a + n) + (b – n)</v-row>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Subtraktion:</strong> Das Ergebnis ändert sich nicht,
                  wenn man beide Zahlen um dieselbe Zahl vergrößert (oder
                  verkleinert).
                </td>
                <td class="no-wrap">
                  <v-row justify="center">13 – 8 = 14 – 9</v-row>
                  <v-row justify="center">a – b = (a + n) – (b + n)</v-row>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Multiplikation:</strong> Das Ergebnis ändert sich
                  nicht, wenn man eine Zahl multipliziert und die andere
                  entsprechend dividiert.
                </td>
                <td class="no-wrap">
                  <v-row justify="center"> 18 · 16 = 36 · 8 </v-row>
                  <v-row justify="center"> a · b = (a · n) · (b ː n) </v-row>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Division:</strong> Das Ergebnis ändert sich nicht,
                  wenn man beide Zahlen durch dieselbe Zahl dividiert (oder
                  entsprechend multipliziert).
                </td>
                <td class="no-wrap">
                  <v-row justify="center">48 ː 6 = 24 ː 3</v-row>
                  <v-row justify="center">a ː b = (a ː n) ː (b ː n)</v-row>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <br />

    <p>
      Bedeutsam sind des Weiteren die Zusammenhänge zwischen den
      Rechenoperationen. Die Multiplikation beispielsweise kann man als
      wiederholte Addition gleicher Summanden verstehen (4 · 7 = 7 + 7 + 7 + 7).
      Sie bildet gleichzeitig die Umkehroperation der Division, was die Lösung
      von Divisionsaufgaben mit Hilfe der Multiplikation zu einer häufig zu
      beobachtenden Lösungsstrategie macht (36 : 4 = 9, weil 9 · 4 = 36). Auch
      das sind Zusammenhänge die Kinder durchaus erkennen und nutzen können.
    </p>

    <v-card outlined raised>
      <v-card-title
        >Was sollen die Schülerinnen und Schüler lernen?</v-card-title
      >
      <v-card-text>
        Die Kinder können ...
        <ul>
          <li>Beziehungen zwischen Aufgaben und Rechenoperationen benennen</li>
          <li>
            die Erklärung einer anderen Person zu Beziehungen zwischen Aufgaben
            und Rechenoperationen wiedergeben
          </li>
          <li>Beziehungen zwischen Aufgaben und Rechenoperationen erklären</li>
          <li>
            mithilfe von Darstellungen in verschiedenen Darstellungsformen
            Beziehungen zwischen Aufgaben und Rechenoperationen darstellen (z.B.
            mit Plättchen, Forschermitteln o.ä.)
          </li>
          <li>
            Zusammenhänge zwischen Aufgaben und Rechenoperationen zum flexiblen
            Rechnen nutzen
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <br />

    <v-row justify="end">
      <v-btn text color="primary" @click="navigateToDiagnose">
        Weiter mit: Diagnose
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </v-row>

    <br/>
    <br/>
    <br/>
    <h4 id="literaturverzeichnis">Literaturverzeichnis</h4>
      <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td>
              Gaidoschik, M. (2007). <i>Rechenschwäche vorbeugen – Erstes Schuljahr: Vom Zählen zum Rechnen.</i> Wien: ÖBV HPT.
            </td>
          </tr>
          <tr>
            <td>
              Häsel-Weide, U., & Nührenbörger, M. (2012). <i>Fördern im Mathematikunterricht.</i> In H. Bartnitzky, U. Hecker & M. Lassek (Hrsg.), <i>Individuell fördern – Kompetenzen stärken – in der Eingangsstufe.</i> Frankfurt am Main: Grundschulverband.
            </td>
          </tr>
          <tr>
            <td>
              Kuhnke, K. (2012). <i>Vorgehensweisen von Grundschulkindern beim Darstellungswechsel – Eine Untersuchung am Beispiel der Multiplikation im 2. Schuljahr.</i> Wiesbaden: Springer.
            </td>
          </tr>
          <tr>
            <td>
              Schipper, W. (2005). <i>Lernschwierigkeiten erkennen - verständnisvolles Lernen fördern.</i> http://www.sinus-angrundschulen.de/fileadmin/uploads/Material_aus_STG/Mathe-Module/M4.pdf
            </td>
          </tr>
          <tr>
            <td>
              Selter, C., & Zannetin, E. (2018). <i>Mathematik unterrichten in der Grundschule. Inhalte – Leitideen – Beispiele.</i> Seelze: Kallmeyer.
            </td>
          </tr>
          <tr>
            <td>
              vom Hofe, R. (1995). <i>Grundvorstellungen mathematischer Inhalte.</i> Heidelberg: Spektrum.
            </td>
          </tr>
          <tr>
            <td>
              Wartha, S., & Schulz, A. (2011). <i>Aufbau von Grundvorstellungen (nicht nur) bei besonderen Schwierigkeiten im Rechnen.</i> Kiel: IPN.
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import AppHelper from "@/common/AppHelper";
export default {
  beforeCreate: function () {
    AppHelper.isUserAllowedRedirectIfNot("ovi");
  },
  components: {
    VuetifyAudio: () => import("vuetify-audio"),
  },
  methods: {
    audioEnded: function (id) {
      AppHelper.trackMatomoEvent(
        this,
        "SWVI",
        "id:33; Audio komplett abgespielt;",
        id
      );
    },
    audioPlayerClicked: function (event, id, ref) {
      if (
        ref.firstPlay &&
        !event.srcElement.classList.contains("v-card__text")
      ) {
        // other than in LernbausteinStempeln a click on the surrounding vcard can trigger an event
        // the first playback can cause an unwated pause event, so we have to check it this way
        AppHelper.trackMatomoEvent(
          this,
          "SWVI",
          "id:32; Audio first play currentTime: " +
            ref.currentTime +
            " duration: " +
            ref.duration +
            ";",
          id
        );
      } else {
        if (event.srcElement.classList.contains("mdi-stop")) {
          AppHelper.trackMatomoEvent(
            this,
            "SWVI",
            "id:34; Audio stop currentTime: " +
              ref.currentTime +
              " duration: " +
              ref.duration +
              ";",
            id
          );
        } else if (event.srcElement.classList.contains("mdi-play")) {
          // der button hat noch das andere logo, es handelt sich aber um pause
          AppHelper.trackMatomoEvent(
            this,
            "SWVI",
            "id:35; Audio pause currentTime: " +
              ref.currentTime +
              " duration: " +
              ref.duration +
              ";",
            id
          );
        } else if (event.srcElement.classList.contains("mdi-pause")) {
          // der button hat noch das andere logo, es handelt sich aber um play
          AppHelper.trackMatomoEvent(
            this,
            "SWVI",
            "id:36; Audio play currentTime: " +
              ref.currentTime +
              " duration: " +
              ref.duration +
              ";",
            id
          );
        } else if (event.srcElement.classList.contains("mdi-volume-mute")) {
          AppHelper.trackMatomoEvent(
            this,
            "SWVI",
            "id:37; Audio mute currentTime: " +
              ref.currentTime +
              " duration: " +
              ref.duration +
              ";",
            id
          );
        } else if (event.srcElement.classList.contains("mdi-volume-high")) {
          AppHelper.trackMatomoEvent(
            this,
            "SWVI",
            "id:38; Audio unmute currentTime: " +
              ref.currentTime +
              " duration: " +
              ref.duration +
              ";",
            id
          );
        }
      }
    },
    videoPlayerEvent: function (event, id) {
      let local_id = 0;
      if (event.type == "play") {
        local_id = 40;
      } else if (event.type == "pause") {
        local_id = 41;
      } else if (event.type == "ended") {
        local_id = 42;
      } else if (event.type == "seeking") {
        local_id = 43;
      } else if (
        event.type == "fullscreenchange" ||
        event.type == "webkitfullscreenchange" ||
        event.type == "mozfullscreenchange" ||
        event.type == "msfullscreenchange"
      ) {
        local_id = 44;
      } else if (event.type == "enterpictureinpicture") {
        local_id = 45;
      } else if (event.type == "leavepictureinpicture") {
        local_id = 46;
      }
      AppHelper.trackMatomoEvent(
        this,
        "SWVI",
        "id:" +
          local_id +
          "; Video " +
          event.type +
          " currentTime: " +
          event.currentTarget.currentTime +
          " duration: " +
          event.currentTarget.duration +
          ";",
        id
      );
    },
    navigateToDiagnose: function () {
      this.$router.push({ name: "ovi_diagnose" });
    },
  },
  computed: {
    gesetzeTabelleFormat() {
      if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs) {
        return 12;
      } else {
        return 6;
      }
    },
    audioTabelleFormat() {
      if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs) {
        return 5;
      } else {
        return 4;
      }
    }
  },
};
</script>

<style scoped>
.no-wrap {
  white-space: nowrap;
}

video, .standAloneAudio {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
